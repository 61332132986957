<template>
  <form @submit.prevent="updateWorkEquipment">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 v-text="headerData.title"></h2>
      <div class="d-flex gap-3">
        <UIButton
          type="button"
          v-show="!editable"
          text="Редактировать"
          leftIcon="bi-pencil"
          @click="goEdit"
        />
        <UIButton
          type="submit"
          v-show="editable"
          text="Сохранить"
          leftIcon="bi-save"
        />
        <UIButton
          type="button"
          v-show="editable"
          text="Удалить"
          leftIcon="bi-trash"
          @click="deleteWorkEquipment"
          float
        />
      </div>
    </div>
    <div class="row gap-3">
      <dl class="col row gap-2">
        <div class="row gap-2">
          <div>
            <dt class="col">Наименование:</dt>
            <dl class="col">
              <EditableInput
                v-model="data.WorkIdentifiedObject.IdentifiedObject.name"
                :editable="editable"
                required
              />
            </dl>
          </div>

          <div>
            <dt class="col">Описание:</dt>
            <dl class="col">
              <EditableInput
                v-model="data.WorkIdentifiedObject.IdentifiedObject.description"
                :editable="editable"
                required
              />
            </dl>
          </div>

          <div>
            <dt class="col">Тариф:</dt>
            <dl class="col">
              <EditableInput
                v-model="data.rate.value"
                type="number"
                step="0.01"
                :editable="editable"
                required
              />
            </dl>
          </div>
        </div>
      </dl>
      <dl class="col">
        <div class="row gap-2">
          <div>
            <dt class="col">Код:</dt>
            <dl class="col">
              <EditableInput v-model="data.code" editable required />
            </dl>
          </div>

          <div>
            <dt class="col">Статус:</dt>
            <dl class="col">
              <EditableSelect
                :value="lodash.get(data, 'status.id')"
                @input="(e) => lodash.set(data, 'status.id', e)"
                :options="status"
                :editable="editable"
                required
                label="Статус"
              />
            </dl>
          </div>
        </div>
      </dl>
    </div>
  </form>
</template>

<script>
import {
  API_work_equipment_view,
  API_work_equipment_update,
  API_work_equipment_delete,
} from "@/services/references/workEquipment";
import { API_StatusList } from "@/services/api";
import UIButton from "@/components/UI/Button";
import EditableInput from "@/components/UI/EditableInput";
import EditableSelect from "@/components/UI/EditableSelect";

export default {
  name: "view",
  components: { UIButton, EditableInput, EditableSelect },
  computed: {
    editable: function () {
      switch (this.$attrs.mode) {
        case "edit":
        case "create":
          return true;
        default:
          return false;
      }
    },
    headerData: function () {
      switch (this.$attrs.mode) {
        case "edit":
          return {
            title: "Редактирование норматива",
          };
        default:
          return {
            title: "Просмотр норматива",
          };
      }
    },
  },
  data() {
    return {
      /**
       * @type WorkEquipmentObject
       */
      data: null,
      status: [],
    };
  },
  mounted() {
    //Загрузка возможных статусов
    API_StatusList().then((res) => {
      this.status = res;
    });
    API_work_equipment_view(parseInt(this.$route.params.workId)).then((res) => {
      this.data = res;
    });
  },
  methods: {
    goEdit() {
      this.$router.push({
        path: `/references/work-equipment/${this.$route.params.workId}/edit`,
      });
    },
    deleteWorkEquipment() {
      API_work_equipment_delete(parseInt(this.$route.params.workId)).finally(
        () => {
          this.$router.push({
            path: "/references/work-equipment/",
          });
        }
      );
    },
    updateWorkEquipment() {
      API_work_equipment_update(
        parseInt(this.$route.params.workId),
        this.data
      ).then(() => {
        this.$router.push({
          path: `/references/work-equipment/${this.$route.params.workId}`,
        });
      });
    },
  },
};
</script>

<style scoped></style>
